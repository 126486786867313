<template lang="">
    <v-card>
        <s-crud
            :config="config"
            title="Vacaciones"
            :filter="filter"
            add
            edit
            remove
            @save="save($event)"
        >
            <template v-slot:filter>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="3">
                            <s-select-definition :def="1060" v-model="filter.FormType" label="Tipo Planilla" :disabled="filter.FormTypeAll?true:false" />
                        </v-col>
                        <v-col cols="12" md="2">
							<s-switch label="Todas las Planillas" v-model="filter.FormTypeAll" :true-value="1" :false-value="0" />
						</v-col>
                        <v-col cols="12" md="2" v-if="!filter.FormTypeAll">
                            <s-select label="Año" :items="years" outlined hide-details dense v-model="year"></s-select>
                        </v-col>
                        <v-col cols="12" md="2" v-if="(filter.FormType == 1 || filter.FormType == 3 || filter.FormType == 4 || filter.FormType == 7 || filter.FormType == 8) && !filter.FormTypeAll">
                            <s-select label="Mes" :items="itemsMonth" outlined hide-details dense v-model="filter.Month"></s-select>
                        </v-col>
                        <v-col cols="12" md="2" v-if="(filter.FormType == 2 || filter.FormType == 5 || filter.FormType == 6) && !filter.FormTypeAll">
                            <s-periods label="Periodo Semana" v-model="week" :year="year" :type="1" return-object></s-periods>
                        </v-col>
                        <v-col cols="12" md="3">
                            <s-select-area 
								label="Area"
								v-model="filter.AreID"
							/>
                        </v-col>
                        <v-col cols="12" md="2" v-if="filter.FormTypeAll">
                                <s-date label="Fecha Inicio" v-model='filter.VctBeginDate'/>
                        </v-col>
                        <v-col cols="12" md="2" v-if="filter.FormTypeAll"> 
                            <s-date label="Fecha Fin" v-model='filter.VctEndDate'></s-date>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row >
                        <v-col cols="12" md="4" class="s-col-form" v-show="isAdmin">
                            <s-toolbar-person ref="toolbarPerson" noCamera label="Documento*" noEdit v-model="props.item.PrsDocumentNumber" @returnPerson="returnPerson($event, props.item)" />
                        </v-col>

                        <v-col cols="12" md="4" class="s-col-form" v-show="isAdmin" style="display:flex; align-items: end">
                            <s-text style="flex:1" readonly label="Persona" v-model="props.item.NtpFullName"></s-text>
                            <div style="height: 100%; display: flex; align-items: center; padding: 0px 5px; padding-top: 15px;">
                                <v-tooltip bottom="">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" x-small style="cursor: pointer;">mdi-information-outline</v-icon>
                                    </template>
                                    <span style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                        <span>Fecha Inicio: {{$fun.formatDateView(itemPerson.CtrBeginDate)}}</span>
                                        <span>Dias Laborador: {{itemPerson.WorkingDays}}</span>
                                        <span>Area: {{itemPerson.AreName}}</span>
                                    </span>
                                </v-tooltip>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" class="s-col-form">
                            <s-date label="F. Inicio" v-model="props.item.VctDateInit" clearable></s-date>
                        </v-col>
                        <v-col cols="12" md="4" class="s-col-form">
                            <s-date label="F. Fin" v-model="props.item.VctDateEnd" clearable></s-date>
                        </v-col>
                        <!-- <v-col cols="12" md="4" class="s-col-form">
                            <s-text
                                label="Comentario" 
                                v-model="props.item.VctReference"/>
                        </v-col> -->
                        <v-col cols="12" md="4" class="s-col-form">
                            <s-select-definition
                            :def="1547"
                            label="Estado"
                            v-model="props.item.VctStatus"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>
    </v-card>
  </template>
  
  <script>
  
    import SText from '../../components/Utils/SText.vue'
    import ServiceVacations from "../../services/HumanResource/HmnVacations";
    import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
    import SSelectPayrollAllow from '../../components/Utils/SSelectPayrollAllow.vue';
  
    export default {
      components: {
        SText,
        sToolbarPerson,
        SSelectPayrollAllow
      },
      data() {
        return {
            filter:{
                AreID: null,
                Month: null,
                Week: null,
                FormType: null,
                FormTypeAll: 0,
                VctBeginDate: null,
                VctEndDate: null,
            },
            config: {
              service: ServiceVacations,
              model: {
                    VctID : "ID",
                    NtpID: "int",
                    NtpFullName: "string",
                    PrsDocumentNumber: "string",
                    VctDateInit: "date",
                    VctDateEnd: "date",
                    CtrBeginDate: "date",
              },
              headers: [
                  { text: "ID", value: "VctID" },
                  { text: "Nombre y Apellidos", value: "NtpFullName" },
                  { text: "DNI", value: "PrsDocumentNumber" },
                  { text: "Tipo de Planilla", value: "TypePayrollName" },
                  { text: "Fecha de Ingreso", value: "CtrBeginDate", width: 100 },
                  { text: "Dias de Vacaciones", value: "VctDays", width: 90 },
                  { text: "Periodo", value: "VctPeriod", width: 90 },
                  { text: "Fecha Inicio", value: "VctDateInit", width: 100 },
                  { text: "Fecha Fin", value: "VctDateEnd", width: 100 },
                  { text: "Saldo de Vacaciones", value: "VctBalance", width: 90 },
                  { text: "Estatus", value: "VctStatusName" },
                //   { text: "Comentario", value: "VctReference" },
              ]
            },
            isAdmin: this.$fun.isAdmin(),
            week: null,
            year: null,
            itemPerson: {},
        }
      },
      methods:{
        returnPerson(value, item) {
            if(value == null){
                return;
            }else{
                this.itemPerson = {...value};
                if (value.CtrBeginDate) {
                    const currentDate = new Date();
                    const ctrBeginDate = new Date(value.CtrBeginDate);
                    const timeDiff = currentDate - ctrBeginDate;
                    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

                    this.itemPerson.WorkingDays = daysDiff;

                    if (daysDiff > 365) {
                        console.log(`El trabajador ${value.NtpFullName} tiene ${daysDiff} días laborados.`);
                        item.NtpFullName = value.NtpFullName;
                        item.PrsDocumentNumber = value.PrsDocumentNumber;
                        item.NtpID = value.NtpID;
                        item.PrsID = value.PrsID;
                        item.WrkID = value.WkrID;
                    }else{
                        this.$fun.alert(`El trabajador ${value.NtpFullName} tiene ${daysDiff} días laborados.`,"warning");
                    }
                }else{
                    this.$fun.alert(`El trabajador ${value.NtpFullName} tiene 0 días laborados.`,"warning");
                    item.NtpFullName = null;
                    item.NtpID = null;
                    item.PrsID = null;
                    item.WrkID = null;
                }
            }
        },
        save(item){
            const usrAdmin = this.$fun.isAdmin();
            
            if(!item.PrsID || !item.NtpID){
                item.PrsDocumentNumber = this.$fun.getUserInfo().PrsDocumentNumber;
                this.isAdmin = true;
                this.$refs.toolbarPerson.returnPerson(
                    {
                        PrsDocumentNumber: item.PrsDocumentNumber
                    }
                );
            }

            setTimeout(() => {
                this.isAdmin = usrAdmin;

                if(item.PrsDocumentNumber.length <= 0){
                    this.$fun.alert("El campo Documento es Obligatorio","warning");
                    return;
                }
                // if(item.VctReference.length <= 0){
                //     this.$fun.alert("El campo Referencia es Obligatorio","warning");
                //     return;
                // }

                if(!item.VctDateEnd || !item.VctDateInit){
                    this.$fun.alert("Las fechas son obligatorias","warning");
                    return;
                }
    
                item.save();
            }, 0);
        },
        selection() {
            if(this.filter.Month!=null){
                let dd = 19;
                let m = this.filter.Month - 1;
                if (m < 1)
                    this.filter.VctBeginDate = this.$moment(this.year - 1 + "1220").format(
                        "YYYY-MM-DD"
                    );
                else {
                    let mo = m <= 9 ? "0" + m : m;
                    this.filter.VctBeginDate = this.$moment(
                            this.year.toString() + mo.toString() + "01"
                        )
                        .add(dd, "days")
                        .format("YYYY-MM-DD");
                }

                let d = 18;
                this.filter.VctEndDate = this.$moment(
                        this.year.toString() +
                        "" +
                        (this.filter.Month <= 9 ? "0" + this.filter.Month.toString() : this.filter.Month) +
                        "01"
                    )
                    .add(d, "days")
                    .format("YYYY-MM-DD");
            }
        }
      },
      created() {
        this.itemsMonth = this.$const.Months;
        this.years = this.$fun.getTop5LastYears();
      },
      watch:{
        'filter.Month'() {
            this.selection();
        },
        year() {
            this.week   =[];
        },
        week() {
            this.filter.VctBeginDate = this.week.PdsDateBegin;
            this.filter.VctEndDate = this.week.PdsDateEnd;
        },
        'filter.FormTypeAll'() {
            this.filter.AreID = null;
            this.filter.FormType = null;
            this.filter.VctBeginDate = this.$fun.getDate("");
            this.filter.VctEndDate = this.$fun.getDate("");
            this.week = [];
        }
      }
    }
  </script>
  